import { get } from "lodash-es";

const customMessages: Record<string, Record<string, Record<string, string>>> = {
  directus_users: {
    email: {
      RECORD_NOT_UNIQUE: "An account already exists for your email",
    },
  },
};

export function useDirectusError(error: DirectusErrorDetails): string {
  if (!(error.extensions?.collection && error.extensions?.field && error.extensions?.code)) return error.message;
  return get(customMessages, [error.extensions.collection, error.extensions.field, error.extensions.code], error.message);
}
